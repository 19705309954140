import { initialState } from "./utils/initialState"

export const patientDetails = {
  SAVE_PATIENT_DETAILS: "SAVE_PATIENT_DETAILS",
  RESET_PATIENT_DETAILS: "RESET_PATIENT_DETAILS",
}

export const patientEnrollment = {
  SAVE_PATIENT: "SAVE_PATIENT_ENROLLMENT",
  RESET_PATIENT: "RESET_PATIENT_ENROLLMENT",
}

export const doctorEnrollment = {
  SAVE_DOCTOR_ENROLLMENT: "SAVE_DOCTOR_ENROLLMENT",
  RESET_DOCTOR_ENROLLMENT: "RESET_DOCTOR_ENROLLMENT",
  SAVE_DOCTOR_ENROLLMENT_DECLARATION: "SAVE_DOCTOR_ENROLLMENT_DECLARATION",
}

export const consentAndAuth = {
  SAVE_CONSENT_AND_AUTH: "SAVE_CONSENT_AND_AUTH",
}

export const documents = {
  SAVE_DOCUMENT: "SAVE_DOCUMENT",
  SAVE_DOCUMENTS: "SAVE_DOCUMENTS",
  RESET_DOCUMENTS: "RESET_DOCUMENTS",
}

export const modal = {
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
}

export const toast = {
  SHOW_TOAST: "SHOW_TOAST",
  HIDE_TOAST: "HIDE_TOAST",
}

export const epharmacy = {
  SAVE_MEDICINES: "SAVE_MEDICINES",
  SAVE_DELIVERY_DETAILS: "SAVE_DELIVERY_DETAILS",
}

export const financialDetails = {
  SAVE_FINANCIAL_DETAILS: "SAVE_FINANCIAL_DETAILS",
}

export const diagnosticSubsidy = {
  SAVE_DIAGNOSTIC_SUBSIDY: "SAVE_DIAGNOSTIC_SUBSIDY",
  RESET_DIAGNOSTIC_SUBSIDY: "RESET_DIAGNOSTIC_SUBSIDY",
}

export const RESET_STATE = "RESET_STATE"

export let AppReducer = (state, action) => {
  switch (action.type) {
    case patientDetails.SAVE_PATIENT_DETAILS:
      return {
        ...state,
        patientDetails: { ...state.patientDetails, ...action.payload },
      }
    case financialDetails.SAVE_FINANCIAL_DETAILS:
      return {
        ...state,
        financialDetails: { ...state.financialDetails, ...action.payload },
      }
    case patientDetails.RESET_PATIENT_DETAILS:
      return {
        ...state,
        patientDetails: { ...initialState.patientDetails },
      }
    case doctorEnrollment.SAVE_DOCTOR_ENROLLMENT:
      return {
        ...state,
        doctorEnrollment: { ...state.doctorEnrollment, ...action.payload },
      }

    case doctorEnrollment.RESET_DOCTOR_ENROLLMENT:
      return {
        ...state,
        doctorEnrollment: { ...initialState.doctorEnrollment },
      }
    case patientEnrollment.SAVE_PATIENT:
      return {
        ...state,
        patientEnrollment: { ...state.patientEnrollment, ...action.payload },
      }

    case patientEnrollment.RESET_PATIENT:
      return {
        ...state,
        patientEnrollment: { ...initialState.patientEnrollment },
      }

    case doctorEnrollment.SAVE_DOCTOR_ENROLLMENT_DECLARATION:
      return {
        ...state,
        wouldLikeToReceiveUpdates: action.payload,
      }
    case consentAndAuth.SAVE_CONSENT_AND_AUTH:
      return {
        ...state,
        consentAndAuth: action.payload,
      }
    case diagnosticSubsidy.SAVE_DIAGNOSTIC_SUBSIDY:
      return {
        ...state,
        diagnosticSubsidy: { ...state.diagnosticSubsidy, ...action.payload },
      }
    case diagnosticSubsidy.RESET_DIAGNOSTIC_SUBSIDY:
      return {
        ...state,
        diagnosticSubsidy: { ...initialState.diagnosticSubsidy },
      }

    case documents.SAVE_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, action.payload],
      }

    case documents.SAVE_DOCUMENTS:
      return {
        ...state,
        documents: [...action.payload],
      }

    case documents.RESET_DOCUMENTS:
      return {
        ...state,
        documents: [],
      }

    case modal.SHOW_MODAL:
      return { ...state, modal: { ...action.payload, isActive: true } }

    case modal.HIDE_MODAL:
      return { ...state, modal: { ...initialState.modal, isActive: false } }

    case toast.SHOW_TOAST:
      return { ...state, toast: { ...action.payload, isActive: true } }

    case toast.HIDE_TOAST:
      return { ...state, toast: { ...initialState.toast } }

    case epharmacy.SAVE_MEDICINES:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: action.payload },
      }

    case epharmacy.SAVE_DELIVERY_DETAILS:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, ...action.payload },
      }

    case RESET_STATE:
      return { ...initialState }

    default:
      return { ...initialState, ...state }
  }
}
