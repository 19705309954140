const modalInitialState = {
  isCard: false,
  isFullheight: false,
  isActive: false,
  content: null,
  heading: "",
  headerClass: null,
  hideCloseButton: false,
  background: {
    color: null,
    opacity: "100",
  },
  disableCloseModal: false,
}

export const initialState = {
  modal: { ...modalInitialState },
  doctorEnrollment: {},
  wouldLikeToReceiveUpdates: [],
  documents: [],
  consentAndAuth: [],
  toast: {
    isActive: false,
    message: null,
    color: null,
  },
}
